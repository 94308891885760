(function() {
  $.fn.extend({
    serializeJSON: function(exclude) {
      exclude || (exclude = []);
      const serializedArray = this.serializeArray();
      return serializedArray.reduce((hash, pair) => {
        pair.value && !(pair.name in exclude) && (hash[pair.name] = pair.value);
        return hash;
      }, {});
    }
  });
}());