var Tabulator = require('tabulator-tables');
import Currency from '../../plugins/utils';
export default function purchase_orders() {
  'use strict';

  // TODO
  const arrayToProductVariantsObj = (array, key) => {
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: `${item['title']} - ${item[key]}`
      }
    }, {});
  };

  const csrfToken = $('meta[name="csrf-token"]').attr("content");

  const getCSRFToken = function(xhr) { xhr.setRequestHeader("X-CSRF-Token", csrfToken) };

  const money = new Currency();
  // data store
  let store = {
    purchaseOrderId: null,
    purchaseOrderMethod: "POST",
    // purchase order line items
    purchaseOrderLineId: 0,
    purchaseOrderLineItems: {},
    removePurchaseOrderLineItems: [],
    totalUnits: 0,
    subtotal: 0,
    total: 0,
    // purchase order procurements
    procurements: {},
    // sku autosuggest
    filteredVariants: {},
    filteredSkus: {},
    selectedStorefront: 'components'
  };

  /* cache DOM elements */
  /* general */
  const $pageContentWrapper = $(".page-content-wrapper");
  const $tabCreate = $("#tab-create");
  const $heading = $("#heading");
  /* list all purchase orders section */
  const $modalProcurements = $("#modalProcurements");
  const $filterField = $("#filter-field");
  const $filterType = $("#filter-type");
  const $filterValue = $("#filter-value");
  const $filterClear = $("#filter-clear");
  /* create purchase order section */
  const $warningMsg = $("#warning");
  const $subtotal = $("#subtotal");
  const $taxes = $("#taxes");
  const $total = $("#total");
  const $poStorefronts = $("#poStorefronts");
  const $poSuppliers = $("#poSuppliers");
  const $poPurchaseOrderNumber = $("#poPurchaseOrderNumber");
  const $poIssuedDate = $("#poIssuedDate");
  const $poStockDue = $("#poStockDue");
  const $poPaymentDue = $("#poPaymentDue");
  const $poReference = $("#poReference");
  const $poEmail = $("#poEmail");
  const $poNotes = $("#poNotes");
  const $poTags = $("#poTags");
  const $taxTreatment = $("input[name='tax-treatment']");
  const $poTaxExclusive = $("#poTaxExclusive");
  const $confirmClosePurchaseOrder = $("#js-confirm-close-purchase-order");
  const $closePurchaseOrder = $("#js-close-purchase-order");
  const $savePurchaseOrder = $("#js-save-purchase-order");
  const $clearPurchaseOrder = $("#js-clear-purchase-order");
  const $addPurchaseOrderLineItem = $("#js-add-purchase-order-line-item");

  // Date Pickers
  $('#poIssuedDate, #poStockDue, #poPaymentDue').datepicker();

  // Toggle Sections
  $('[data-toggle="section"]').on("click", function(e) {
    e.preventDefault();
    const target = $(this).data("target");
    const tablePurchaseOrder = Tabulator.prototype.findTable("#poTable")[0];
    const tablePurchaseOrderLineItems = Tabulator.prototype.findTable("#polinesTable")[0];
    if (target === "#tab-purchase-orders") {
      clearPurchaseOrderForm();
      clearStore();
      if (tablePurchaseOrder) {
        tablePurchaseOrder.redraw();
      }
      if (tablePurchaseOrderLineItems) {
        tablePurchaseOrderLineItems.clearData();
      }
    }
    else if (target === "#tab-create") {
      store.purchaseOrderId = null;
      store.purchaseOrderMethod = "POST";
      $heading.text("New");
      $poStorefronts.attr("disabled", false);
      $poStorefronts.val("components");
      $poStorefronts.trigger("change");
      $confirmClosePurchaseOrder.addClass("hide");
      $warningMsg.addClass("hide");
      if (tablePurchaseOrderLineItems) {
        tablePurchaseOrderLineItems.redraw();
      }
    }
    $(".tab-pane.active").removeClass("active");
    $(target).addClass("active");
  });

  const UTCToDatePicker = (UTCDate) => {
    const date = UTCDate.split("T")[0].split("-");
    return `${date[1]}/${date[2]}/${date[0]}`;
  };

  const notify = (type, message) => {
    $pageContentWrapper.pgNotification({
      style: "bar",
      message: message,
      position: "top",
      timeout: 0,
      type: type
    }).show();
  };

  const updateSummary = () => {
    const purchaseOrderLineItems = Object.values(store.purchaseOrderLineItems);
    const totalUnits = purchaseOrderLineItems.reduce((acc, row) => {
      return acc + row.quantity;
    }, 0);
    const subtotal = purchaseOrderLineItems.reduce((acc, row) => {
      return acc + row.total
    }, 0);
    const taxes = purchaseOrderLineItems.reduce((acc, row) => {
      if (!row.tax) { row.tax = 0; }
      return acc + (row.tax * 0.01 * row.total)
    }, 0);
    let total;
    $subtotal.text(money.from((subtotal * 0.01).toFixed(2)).format("USD"));
    $taxes.text(money.from((taxes * 0.01).toFixed(2)).format("USD"));
    if ($("input[name='tax-treatment']:checked").val() === "inclusive") {
      total = subtotal + taxes;
      $total.text(money.from((total * 0.01).toFixed(2)).format("USD"));
    } else {
      total = subtotal;
      $total.text(money.from((subtotal * 0.01).toFixed(2)).format("USD"));
    }
    store.totalUnits = totalUnits;
    store.subtotal = subtotal;
    store.total = total;
  };

  const clearPurchaseOrderForm = () => {
    $poStorefronts.val("components");
    $poStorefronts.trigger("change");
    $poSuppliers.val($poSuppliers.prop('selectedIndex', 0).val());
    $poSuppliers.trigger("change");
    $poPurchaseOrderNumber.val("");
    $poIssuedDate.val("").datepicker("update");
    $poStockDue.val("").datepicker("update");
    $poPaymentDue.val("").datepicker("update");
    $poReference.val("");
    $poEmail.val("");
    $poNotes.val("");
    $poTags.val("");
    $poTaxExclusive.attr('checked', true).click();
    $subtotal.text(money.from((0).toFixed(2)).format("USD"));
    $taxes.text(money.from((0).toFixed(2)).format("USD"));
    $total.text(money.from((0).toFixed(2)).format("USD"));
  };

  const clearStore = () => {
    const purchaseOrderId = store.purchaseOrderId;
    const purchaseOrderMethod = store.purchaseOrderMethod;
    store = {
      purchaseOrderId: purchaseOrderId,
      purchaseOrderMethod: purchaseOrderMethod,
      purchaseOrderLineId: 0,
      purchaseOrderLineItems: {},
      removePurchaseOrderLineItems: [],
      totalUnits: 0,
      subtotal: 0,
      total: 0,
      procurements: {},
      selectedStorefront: 'components',
      filteredVariants: store.filteredVariants,
      filteredSkus: store.filteredSkus
    };
  };

  const getPurchaseOrderParams = () => {
    let purchaseOrderLineItems = Object.values(store.purchaseOrderLineItems).map((purchaseOrderLineItem) => {
      if (purchaseOrderLineItem && purchaseOrderLineItem.quantity && purchaseOrderLineItem.cost_per_unit) {
        let temp = {
          sku: purchaseOrderLineItem.sku,
          /* TODO: fix sku / name */
          quantity: purchaseOrderLineItem.quantity,
          unit_of_measure: purchaseOrderLineItem.unit_of_measure,
          cost_per_unit: purchaseOrderLineItem.cost_per_unit,
          tax: purchaseOrderLineItem.tax,
          total: purchaseOrderLineItem.total
        };
        if (purchaseOrderLineItem.id) {
          temp['id'] = parseInt(purchaseOrderLineItem.id);
        }
        return temp;
      }
    });
    store.removePurchaseOrderLineItems.forEach(function(id) {
      purchaseOrderLineItems.push({ id: id, _destroy: 1 });
    });
    return {
      supplier_id: $poSuppliers.val(),
      purchase_order_number: $poPurchaseOrderNumber.val(),
      issued_at: $poIssuedDate.datepicker('getUTCDate'),
      stock_due: $poStockDue.datepicker('getUTCDate'),
      payment_due: $poPaymentDue.datepicker('getUTCDate'),
      reference: $poReference.val(),
      email: $poEmail.val(),
      currency: "USD",
      note: $poNotes.val(),
      tags: $poTags.val(),
      total_units: store.totalUnits,
      subtotal: store.subtotal,
      total: store.total,
      tax_treatment: $("input[name='tax-treatment']:checked").val(),
      status: "active",
      purchase_order_line_items_attributes: purchaseOrderLineItems
    };
  }

  const createPurchaseOrder = (callback) => { 
    const purchaseOrderParams = getPurchaseOrderParams();
    purchaseOrderParams["procurement_status"] = "unprocured";
    const endpoint = "/purchase_orders";
    const messageSuccess = "Success! Purchase order created.";
    const messageError = "Error! Purchase order could not be created.";
    $.ajax({
      dataType: "json",
      url: endpoint,
      type: "POST",
      "beforeSend": getCSRFToken,
      data: { storefront_channel: $poStorefronts.val(), purchase_order: purchaseOrderParams },
      success: function(response) {
        if (response && response.id) {
          notify("success", messageSuccess);
          callback(response);
        }
      },
      error: function() {
        notify("danger", messageError);
      }
    });
  };

  const updatePurchaseOrder = (type, callback) => {
    const purchaseOrderParams = getPurchaseOrderParams();
    const endpoint = `/purchase_orders/${store.purchaseOrderId}`;
    const messageSuccess = "Success! Purchase order updated.";
    const messageError = "Error! Purchase order could not be updated.";
    $.ajax({
      dataType: "json",
      url: endpoint,
      type: "PUT",
      "beforeSend": getCSRFToken,
      data: { storefront_channel: $poStorefronts.val(), purchase_order: purchaseOrderParams, type: type },
      success: function(response) {
        if (response && response.id) {
          notify("success", messageSuccess);
          if (callback) {
            callback(response);
          }
        }
      },
      error: function() {
        notify("danger", messageError);
      }
    });
  };

  /* TODO: get procurements from procurements route rather than from purchase_orders */
  const getProcurements = (purchaseOrderId) => {
    const table = Tabulator.prototype.findTable("#procurementsTable")[0];
    $.ajax({
      dataType: "json",
      url: `/purchase_orders/${purchaseOrderId}`,
      type: "GET",
      "beforeSend": getCSRFToken,
      success: function(response) {
        const lineItems = response.purchase_order_line_items.map((purchaseOrderLineItem) => {
          return {
            name: purchaseOrderLineItem.name,
            quantity: purchaseOrderLineItem.quantity,
            cost_per_unit: (purchaseOrderLineItem.cost_per_unit * 0.0001).toFixed(4),
            total: (purchaseOrderLineItem.total * 0.01).toFixed(2),
            //after: "", // TODO
            purchase_order_id: purchaseOrderId,
            id: purchaseOrderLineItem.id,
            title: "Incoming"
          }
        });
        const procurementLineItems = response.procurement_line_items.map((procurementLineItem) => {
          return {
            name: procurementLineItem.name,
            quantity: procurementLineItem.quantity,
            cost_per_unit: (procurementLineItem.cost_per_unit * 0.0001).toFixed(4),
            total: (procurementLineItem.total * 0.01).toFixed(2),
            //after: "",
            purchase_order_id: purchaseOrderId,
            id: procurementLineItem.id,
            title: "Received " + procurementLineItem.received_at.split("T")[0]
          }
        });
        const lineItemsAndProcurementLineItems = lineItems.concat(procurementLineItems);
        table.redraw(true);
        table.setData(lineItemsAndProcurementLineItems);
        table.redraw(true);
      }
    });
  };

  const getPurchaseOrder = (purchaseOrderId) => {
    const table = Tabulator.prototype.findTable("#polinesTable")[0];
    $.ajax({
      dataType: "json",
      url: `/purchase_orders/${purchaseOrderId}`,
      type: "GET",
      "beforeSend": getCSRFToken,
      success: function(response) {
        const lineItems = response.purchase_order_line_items.map((purchaseOrderLineItem, index) => {
          store.purchaseOrderLineItems[index] = {
            id: purchaseOrderLineItem.id,
            sku: purchaseOrderLineItem.sku,
            quantity: purchaseOrderLineItem.quantity,
            unit_of_measure: purchaseOrderLineItem.unit_of_measure,
            cost_per_unit: purchaseOrderLineItem.cost_per_unit,
            tax: purchaseOrderLineItem.tax,
            total: purchaseOrderLineItem.total
          };
          store.purchaseOrderLineId = index;
          return {
            sku: purchaseOrderLineItem.sku,
            quantity: purchaseOrderLineItem.quantity,
            unit_of_measure: purchaseOrderLineItem.unit_of_measure,
            after: "", // TODO
            cost_per_unit: (purchaseOrderLineItem.cost_per_unit * 0.0001).toFixed(4),
            tax: purchaseOrderLineItem.tax,
            total: (purchaseOrderLineItem.total * 0.01).toFixed(2),
            purchase_order_id: purchaseOrderId,
            id: store.purchaseOrderLineId
          }
        });
        table.redraw(true);
        table.setData(lineItems);
        table.redraw(true);

        const tags = response.tags; //JSON.parse(response.tags);
        $poSuppliers.val(response.supplier_id);
        $poSuppliers.trigger("change");
        $poStorefronts.val(response.storefront.channel);
        $poStorefronts.trigger("change");
        $poPurchaseOrderNumber.val(response.purchase_order_number);
        if (response.issued_at) { $poIssuedDate.datepicker("update", UTCToDatePicker(response.issued_at)); }
        if (response.stock_due) { $poStockDue.datepicker("update", UTCToDatePicker(response.stock_due)); }
        if (response.payment_due) { $poPaymentDue.datepicker("update", UTCToDatePicker(response.payment_due)); }
        $poReference.val(response.reference);
        $poEmail.val(response.email);
        $poNotes.val(response.note);
        $poTags.val(tags);
        $poTaxExclusive.attr('checked', true).click();

        updateSummary();
      }
    });
  };

  const populateStorefronts = (storefronts) => {
    const options = storefronts.reduce((html, storefront) => {
      return `${html}
      <option value="${storefront}">${storefront}</option>`
    }, '');
    $poStorefronts.html(options);
    $poStorefronts.select2({
      minimumResultsForSearch: ($(this).attr('data-disable-search') == 'true' ? -1 : 1)
    }).on('select2:open', function() {
      $.fn.scrollbar && $('.select2-results__options').scrollbar({
        ignoreMobile: false
      });
    });
  };

  const populateSuppliers = (suppliers) => {
    const options = suppliers.reduce((html, supplier) => {
      return `${html}
      <option value="${supplier.id}">${supplier.name}</option>`
    }, '');
    $poSuppliers.html(options);
    // Suppliers Dropdown
    $poSuppliers.select2({
      minimumResultsForSearch: ($(this).attr('data-disable-search') == 'true' ? -1 : 1)
    }).on('select2:open', function() {
      $.fn.scrollbar && $('.select2-results__options').scrollbar({
        ignoreMobile: false
      });
    });
  };

  const populatePurchaseOrderForm = (purchaseOrderId) => {
    const purchaseOrder = getPurchaseOrder(purchaseOrderId);
  }

  const initFilters = (table) => {
    let filterField = "purchase_order_number"; //$filterField.val();
    let filterType = "like"; //$filterType.val();
    //Custom filter example
    function customFilter(data) {
      return data;
    }
    //Trigger setFilter function with correct parameters
    function updateFilter() {
      var filter = $filterField.val() == "function" ? customFilter : filterField;
      if ($filterField.val() == "function") {
        $filterType.prop("disabled", true);
        $filterValue.prop("disabled", true);
      } else {
        $filterType.prop("disabled", false);
        $filterValue.prop("disabled", false);
      }
      table.setFilter(filter, filterType, $filterValue.val());
    }

    //Update filters on value change
    $("#filter-field, #filter-type").change(updateFilter);
    $filterValue.keyup(updateFilter);

    //Clear filters on "Clear Filters" button click
    $filterClear.click(function() {
      $filterField.val("");
      $filterType.val("=");
      $filterValue.val("");
      table.clearFilter();
    });
  };

  const renderPurchaseOrderTable = (tableData, preRender, callback) => {
    preRender(function(suppliers) {
      const packageIcon = function(cell, formatterParams, onRendered) {
        return "<i class='pg-inbox'></i>";
      };
      const documentIcon = function(cell, formatterParams, onRendered) { //plain text value
        return "<i class='fa fa-pencil-square-o'></i>";
      };
      //define table
      let table = new Tabulator("#poTable", {
        layout: "fitDataStretch",
        placeholder: "No Data Available",
        data: tableData,
        height: "calc(100vh - 76px)",
        columns: [
          { title: "Order #", field: "purchase_order_number", width: 200 },
          { title: "Status", field: "status", width: 120, formatter: "html" },
          { title: "Received", field: "procurement_status", width: 100, formatter: "html" },
          { title: "Supplier", field: "supplier", width: 200 },
          { title: "Total Units", field: "total_units", width: 120 },
          { title: "Total Cost", field: "total", width: 150 },
          { title: "Due", field: "payment_due", width: 150 },
          { title: "Received At", field: "received_at", width: 150 },
          {
            formatter: documentIcon,
            align: "right",
            headerSort: false,
            cellClick: function(e, cell) {
              const purchaseOrderId = cell.getRow().getData().id;
              $(".tab-pane.active").removeClass("active");
              $tabCreate.addClass("active");
              $heading.text("Edit");
              $poStorefronts.attr("disabled", true);
              $confirmClosePurchaseOrder.removeClass("hide");
              $warningMsg.removeClass("hide");
              store.purchaseOrderId = purchaseOrderId;
              store.purchaseOrderMethod = "PUT";
              populatePurchaseOrderForm(purchaseOrderId);
            }
          },
          {
            formatter: packageIcon,
            align: "right",
            headerSort: false,
            cellClick: function(e, cell) {
              const purchaseOrderId = cell.getRow().getData().id;
              $modalProcurements.modal("show");
              getProcurements(purchaseOrderId);
            }
          },
          { title: "id", field: "id", visible: false }
        ]
      });
      initFilters(table);
      if (callback) callback(table);
    });
  };

  const renderProcurementsTable = () => {
    const maxQuantity = (cell, value, params) => {
      return parseInt(value) <= parseInt(cell.getRow().getData().quantity);
    };

    let procurementsTable = new Tabulator("#procurementsTable", {
      layout: "fitColumns",
      groupBy: "title",
      height: "100vh",
      rowSelected: function(row) {
        if (!store.procurements[row.getData().id]) {
          store.procurements[row.getData().id] = row.getData();
        }
      },
      columns: [/*{
          formatter: "rowSelection",
          titleFormatter: "rowSelection",
          align: "center",
          headerSort: false,
          cellClick: function(e, cell) {
            cell.getRow().toggleSelect();
          },
          width: 50
        },*/
        { title: "Item Name", field: "name", headerSort: false },
        {
          title: "Quantity",
          field: "quantity",
          bottomCalc: "sum",
          headerSort: false
        },
        { title: "Cost Per Unit", field: "cost_per_unit", headerSort: false },
        { title: "Total", field: "total", bottomCalc: "sum", headerSort: false },
        //{ title: "After", field: "after", headerSort: false },
        { field: "purchase_order_id", visible: false, headerSort: false },
        { field: "id", visible: false, headerSort: false },
        { title: "Title", field: "title", visible: false, headerSort: false }
      ]
    });
  };

  const filterVariants = (storefront, variants) => {
    return variants.filter((variant) => {
      if (storefront === "components") {
        return true;
      }
      return variant.status === "active";
    });
  };

  $poStorefronts.on("change", function() {
    store.selectedStorefront = $(this).val();
  });

  const renderPurchaseOrderLineItemsTable = (tableData, preRender, callback) => {
    preRender(function(variants) {
      if (Object.keys(store.filteredSkus).length === 0) {
        const storefronts = Object.keys(variants);
        const sorted_storefronts = storefronts.sort((a, b) => {
          const storefrontA = a.toUpperCase();
          const storefrontB = b.toUpperCase();
          if (storefrontA < storefrontB) {
            return -1;
          }
          if (storefrontA > storefrontB) {
            return 1;
          }
        
          // names must be equal
          return 0;
        });
        populateStorefronts(sorted_storefronts);
        storefronts.map((storefront) => {
          store.filteredVariants[storefront] = filterVariants(storefront, variants[storefront]);
          store.filteredSkus[storefront] = arrayToProductVariantsObj(store.filteredVariants[storefront], 'sku');
        })
      }
      // formatters
      const placeHolder = function(cell, formatterParams) {
        var cellValue = cell.getValue();
        if (cellValue === "") {
          return "Start typing SKU or name..."
        } else {
          return cellValue;
        }
      };
      const disabled = function(cell, formatterParams) {
        cell.getElement().style.backgroundColor = "#eee";
        return cell.getValue();
      }
      const deleteCellFormatter = function(cell, formatterParams) {
        disabled(cell, formatterParams);
        return '<i class="fa fa-trash"></i>';
      };
      const removeRow = function(cell) {
        const id = cell.getRow().getData().id;
        if (store.purchaseOrderLineItems[id]) {
          if (store.purchaseOrderMethod === "PUT") { store.removePurchaseOrderLineItems.push(store.purchaseOrderLineItems[id].id) }
          delete store.purchaseOrderLineItems[id];
        }
        cell.getRow().delete();
        updateSummary();
      };
      //define table
      let table = new Tabulator("#polinesTable", {
        layout: "fitDataStretch",
        data: tableData,
        columns: [{
            title: "Item Name/SKU",
            field: "sku",
            width: 250,
            headerSort: false,
            editor: "autocomplete",
            editorParams: function(cell) {
              return {
                showListOnEmpty: true,
                freetext: false, //allow the user to set the value of the cell to a free text entry
                allowEmpty: false,
                values: store.filteredSkus[store.selectedStorefront],
                sortValuesList: "asc", //if creating a list of values from values:true then choose how it should be sorted
              }
            },
            formatter: placeHolder,
            validator: ["required", "unique"],
            cellEdited: function(cell) {
              const id = cell.getRow().getData().id;
              if (!store.purchaseOrderLineItems[id]) { store.purchaseOrderLineItems[id] = {}; }
              store.purchaseOrderLineItems[id].sku = cell.getValue();
            }
          },
          {
            title: "Measurements",
            field: "unit_of_measure",
            width: "200",
            headerSort: false,
            editor: "select",
            editorParams: { values: ["eaches", "grams", "kilograms", "milliliters", "liters", "gallons"] },
            validator: "required",
            cellEdited: function(cell) {
              const id = cell.getRow().getData().id;
              if (!store.purchaseOrderLineItems[id]) { store.purchaseOrderLineItems[id] = {}; }
              store.purchaseOrderLineItems[id].unit_of_measure = cell.getValue();
            }
          },
          {
            title: "Quantity",
            field: "quantity",
            width: 150,
            headerSort: false,
            editor: "input",
            validator: ["required", "integer"],
            cellEdited: function(cell) {
              const id = cell.getRow().getData().id;
              if (!store.purchaseOrderLineItems[id]) { store.purchaseOrderLineItems[id] = {}; }
              store.purchaseOrderLineItems[id].quantity = parseInt(cell.getValue());
              if (cell.getRow().getData().cost_per_unit !== "<empty string>") {
                const total = (cell.getValue() * money.from(cell.getRow().getData().cost_per_unit, true).format() * 0.01);
                store.purchaseOrderLineItems[id].total = total;
                table.updateData([{ id: id, total: (total * 0.01).toFixed(2) }]);
                updateSummary();
              }
            }
          },
          //{ title: "After", field: "after", width: 150, headerSort: false },
          {
            title: "Cost Per Unit",
            field: "cost_per_unit",
            width: 150,
            headerSort: false,
            editor: "input",
            validator: ["required", "regex:^[0-9]+(\\.\\d{1,4})?$"],
            cellEdited: function(cell) {
              const id = cell.getRow().getData().id;
              if (!store.purchaseOrderLineItems[id]) { store.purchaseOrderLineItems[id] = {}; }
              store.purchaseOrderLineItems[id].cost_per_unit = money.from(cell.getValue(), true).format();
              if (cell.getRow().getData().quantity !== "<empty string>") {
                const total = (money.from(cell.getValue(), true).format() * cell.getRow().getData().quantity * 0.01);
                store.purchaseOrderLineItems[id].total = total;
                table.updateData([{ id: id, total: (total * 0.01).toFixed(2) }]);
                updateSummary();
              }
            }
          },
          {
            title: "Tax",
            field: "tax",
            width: 150,
            headerSort: false,
            editor: "select",
            editorParams: { "0": "Tax Exempt (0%)", "15": "Sales Tax (15%)" },
            cellEdited: function(cell) {
              const id = cell.getRow().getData().id
              if (!store.purchaseOrderLineItems[id]) { store.purchaseOrderLineItems[id] = {}; }
              store.purchaseOrderLineItems[id].tax = parseInt(cell.getValue());
              if (cell.getRow().getData().quantity !== "<empty string>" && cell.getRow().getData().cost_per_unit !== "<empty string>") {
                updateSummary();
              }
            }
          },
          { title: "Total", field: "total", width: 200, headerSort: false, formatter: disabled },
          { formatter: deleteCellFormatter, width: 30, align: "right", headerSort: false, cellClick: function(e, cell) { removeRow(cell) } }
        ]
      });

      callback(table);
    });
  };

  const getSuppliers = (callback) => {
    $.ajax({
      dataType: "json",
      url: "/suppliers",
      type: "GET",
      "beforeSend": getCSRFToken,
      success: function(response) {
        populateSuppliers(response);
        if (callback) callback(response);
      }
    });
  };

  const getProductVariants = (callback) => {
    $.ajax({
      dataType: "json",
      url: "/product_variants",
      type: "GET",
      data: {filter_by_storefronts: true},
      "beforeSend": getCSRFToken,
      success: function(response) {
        if (callback) callback(response);
      }
    });
  };

  const addRow = (table, data) => {
    table.addRow({
      purchase_order_number: data.purchase_order_number,
      status: `<span class="label label-${data.status} p-t-5 p-b-5 inline fs-12">${data.status}</span>`,
      procurement_status: data.procurement_status === 'procured' ? '<icon class="fa fa-circle"></fa>' : data.procurement_status === 'unprocured' ? '<icon class="fa fa-circle-o"></fa>' : '<icon class="fa fa-adjust"></fa>',
      total_units: data.total_units,
      supplier: data.supplier.name,
      total: (data.total * 0.01).toFixed(2),
      payment_due: data.payment_due ? data.payment_due.split("T")[0] : '',
      received_at: data.received_at ? data.received_at.split("T")[0] : '',
      id: data.id
    });
  }

  // Initialize datatable with ability to add rows dynamically
  const init = () => {
    $("[data-toggle='modal-confirm-close-purchase-order']").click(function(e) {
      e.preventDefault();
      $('#modal-confirm-close-purchase-order').modal('show');
    });

    $.ajax({
      dataType: "json",
      url: "/purchase_orders",
      type: "GET",
      "beforeSend": getCSRFToken,
      success: function(response) {
        const purchaseOrderData = response.map((purchaseOrder) => {
          let cells = {
            purchase_order_number: purchaseOrder.purchase_order_number,
            status: `<span class="label label-${purchaseOrder.status} p-t-5 p-b-5 inline fs-12">${purchaseOrder.status}</span>`,
            procurement_status: purchaseOrder.procurement_status === 'procured' ? '<icon class="fa fa-circle"></fa>' : purchaseOrder.procurement_status === 'unprocured' ? '<icon class="fa fa-circle-o"></fa>' : '<icon class="fa fa-adjust"></fa>',
            supplier: purchaseOrder.supplier.name,
            total_units: purchaseOrder.total_units,
            unit_of_measure: purchaseOrder.unit_of_measure,
            total: (purchaseOrder.total * 0.01).toFixed(2),
            payment_due: purchaseOrder.payment_due ? purchaseOrder.payment_due.split("T")[0] : '',
            received_at: purchaseOrder.received_at ? purchaseOrder.received_at.split("T")[0] : '',
            id: purchaseOrder.id
          }
          return cells;
        });

        let purchaseOrderLineItemsData = [{
          id: store.purchaseOrderLineId,
          name: "",
          quantity: "",
          unit_of_measure: "",
          after: "",
          cost_per_unit: "",
          tax: "",
          total: ""
        }];

        renderPurchaseOrderTable(purchaseOrderData, getSuppliers, function(table) {
          renderProcurementsTable();
          $savePurchaseOrder.click((e) => {
            e.preventDefault();
            if (store.purchaseOrderMethod === "POST") {
              createPurchaseOrder((response) => {
                addRow(table, response);
              });
            } else {
              updatePurchaseOrder("edit", (response) => {
                addRow(table, response);
              });
            }
          });
          $closePurchaseOrder.click((e) => {
            e.preventDefault();
            if (store.purchaseOrderMethod === "PUT") {
              updatePurchaseOrder("close");
            }
          });
        });

        renderPurchaseOrderLineItemsTable(purchaseOrderLineItemsData, getProductVariants, function(table) {
          /* TODO: create supplier
          $("#create-supplier").click(function(e) {
            e.preventDefault();
          }); */

          $addPurchaseOrderLineItem.click(function(e) {
            e.preventDefault();
            store.purchaseOrderLineId = store.purchaseOrderLineId + 1;
            table.addRow({
              id: store.purchaseOrderLineId,
              name: "",
              quantity: "",
              unit_of_measure: "",
              after: "",
              cost_per_unit: "",
              tax: "",
              total: ""
            });
          });

          $taxTreatment.on("change", function() {
            updateSummary();
          });

          $clearPurchaseOrder.on("click", function(e) {
            e.preventDefault();
            clearPurchaseOrderForm();
            clearStore();
            table.clearData();
            table.addRow({
              id: store.purchaseOrderLineId,
              name: "",
              quantity: "",
              unit_of_measure: "",
              after: "",
              cost_per_unit: "",
              tax: "",
              total: ""
            });
          });
        });
      }
    });

  };

  return init;
}