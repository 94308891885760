export default function mappings() {
  'use strict';

  var responsiveHelper = undefined;
  var breakpointDefinition = {
    tablet: 1024,
    phone: 480
  };

  let t;

  var addMapping = function(name, method, callback) {
    $.ajax({
      dataType: "json",
      url: "/shipping/create_mapping",
      type: "POST",
      "beforeSend": function(xhr) { xhr.setRequestHeader("X-CSRF-Token", $('meta[name="csrf-token"]').attr("content")) },
      data: { name, method },
      success: function(response) {
        callback(response.ship_title);
      }
    })
  };

  let changes = {};

  var saveChanges = function() {
    setTimeout(function() {
      $.ajax({
        dataType: "json",
        url: "/shipping/update_mappings",
        type: "PUT",
        "beforeSend": function(xhr) { xhr.setRequestHeader("X-CSRF-Token", $('meta[name="csrf-token"]').attr("content")) },
        data: { mappings: JSON.stringify(changes) },
        success: function(response) {
          // notify
        }
      })
    }, 0);
  };

  // Initialize datatable with ability to add rows dynamically
  var init = function() {
    var table = $('#mappingTable');

    const createdCell = function(cell) {
      let original;

      cell.setAttribute('contenteditable', true);
      cell.setAttribute('spellcheck', false);

      cell.addEventListener('focus', function(e) {
        original = e.target.textContent;
      })

      cell.addEventListener('blur', function(e) {
        if (original !== e.target.textContent) {
          const row = t.row(e.target.parentElement);
          row.invalidate();
          const data = row.data();
          changes[data[0]] = { id: data[0], name: data[1], method: data[2] }
        }
      })
    };

    const createdCellDropdown = function(cell) {
      cell.addEventListener('change', function(e) {
        const row = t.row(e.target.parentElement);
        row.invalidate();
        const data = row.data();
        const selected = $("body").find("#" + data[0] + "start option:selected").val();
        changes[data[0]] = { id: data[0], name: data[1], method: selected }
      })
    };

    var settings = {
      "columnDefs": [{
          targets: [1],
          createdCell: createdCell
        },
        {
          targets: [0],
          visible: false
        },
        {
          targets: [2],
          createdCell: createdCellDropdown
        }
      ],
      "sDom": "<t><'row'<p i>>",
      "destroy": true,
      "scrollCollapse": true,
      "oLanguage": {
        "sLengthMenu": "_MENU_ ",
        "sInfo": "Showing <b>_START_ to _END_</b> of _TOTAL_ entries"
      },
      "select": {
        "style": 'os',
        "selector": 'td:first-child'
      },
      "iDisplayLength": 100
    };

    t = table.DataTable(settings);

    $('#show-modal').click(function() {
      $('#addNewAppModal').modal('show');
    });

    $('#add-mapping').click(function() {
      const name = $("#mapTitle").val();
      const method = $("#mapMethod").val();
      $("#mapMethod").find('[data-ship-method-id="' + method + '"]').attr('selected', 'selected');
      addMapping(name, method, function(ship_title) {
        const options = '<select id="' + ship_title.id + 'start" value="' + ship_title.ship_method_id + '">' + $("#mapMethod").html() + '</select>';
        t.row.add([ship_title.id, name, options]);
        t.draw();
      });
      $('#addNewAppModal').modal('hide');
    });

    $('#update-mappings').click(function() {
      saveChanges();
    })
  }

  return init;
}