import Calendar from 'tui-calendar';
import moment from 'moment';
var Chance = require('chance');
require('../../plugins/bootstrap-daterangepicker/bootstrap-daterangepicker');
require('../../plugins/jquery-bootstrap-form-wizard/jquery.bootstrap.formwizard');

export default function theme_schedulers() {
  'use strict';
  const csrfToken = $('meta[name="csrf-token"]').attr("content");
  const getCSRFToken = function(xhr) { xhr.setRequestHeader("X-CSRF-Token", csrfToken) };

  /* cache DOM elements */
  /* general */
  const $pageContentWrapper = $(".page-content-wrapper");
  const $sections = $('[data-toggle="section"]');

  const notify = (type, message) => {
    $pageContentWrapper.pgNotification({
      style: "bar",
      message: message,
      position: "top",
      timeout: 0,
      type: type
    }).show();
  };

  // Toggle Sections
  $sections.on("click", function(e) {
    e.preventDefault();
    const target = $(this).data("target");
    $(".tab-pane.active").removeClass("active");
    $(target).addClass("active");
    $("body").trigger("pane-toggle");
    if (target === "#tab-create") {
      console.log('tab create targetted');
      $("#tab1").addClass("active");
    }
  });

  $('#rootwizard').bootstrapWizard({
    onTabShow: function(tab, navigation, index) {
      var $total = navigation.find('li').length;
      var $current = index + 1;

      // If it's the last tab then hide the last button and show the finish instead
      if ($current >= $total) {
        $('#rootwizard').find('.pager .next').hide();
        $('#rootwizard').find('.pager .finish').show().removeClass('disabled hidden');
      } else {
        $('#rootwizard').find('.pager .next').show();
        $('#rootwizard').find('.pager .finish').hide();
      }

      var li = navigation.find('li a.active').parent();

      var btnNext = $('#rootwizard').find('.pager .next').find('button');
      var btnPrev = $('#rootwizard').find('.pager .previous').find('button');

      // remove fontAwesome icon classes
      function removeIcons(btn) {
        btn.removeClass(function(index, css) {
          return (css.match(/(^|\s)fa-\S+/g) || []).join(' ');
        });
      }

      if ($current > 1 && $current < $total) {
        var nextIcon = li.next().find('.fa');
        var nextIconClass = nextIcon.attr('class').match(/fa-[\w-]*/).join();

        removeIcons(btnNext);
        btnNext.addClass(nextIconClass + ' btn-animated from-left fa');

        var prevIcon = li.prev().find('.fa');
        var prevIconClass = prevIcon.attr('class').match(/fa-[\w-]*/).join();

        removeIcons(btnPrev);
        btnPrev.addClass(prevIconClass + ' btn-animated from-left fa');
      } else if ($current == 1) {
        // remove classes needed for button animations from previous button
        btnPrev.removeClass('btn-animated from-left fa');
        removeIcons(btnPrev);
      } else {
        // remove classes needed for button animations from next button
        btnNext.removeClass('btn-animated from-left fa');
        removeIcons(btnNext);
      }
    },
    onNext: function(tab, navigation, index) {
      console.log("Showing next tab");
    },
    onPrevious: function(tab, navigation, index) {
      console.log("Showing previous tab");
    },
    onInit: function() {
      $('#rootwizard ul').removeClass('nav-pills');
    }
  });

  $('#daterangepicker-theme-schedule').daterangepicker({
    affixed: true,
    alwaysShowCalendars: true,
    autoApply: true,
    timePicker: true,
    timePickerIncrement: 5,
    format: 'MM/DD/YYYY h:mm A',
    minYear: new Date().getFullYear(),
    ranges: {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    },
  }, function(start, end, label) {

  });

  /* Calendar */
  var CalendarList = [];

  function CalendarInfo() {
    this.id = null;
    this.name = null;
    this.checked = true;
    this.color = null;
    this.bgColor = null;
    this.borderColor = null;
    this.dragBgColor = null;
  }

  function addCalendar(calendar) {
    CalendarList.push(calendar);
  }

  function findCalendar(id) {
    var found;

    CalendarList.forEach(function(calendar) {
      if (calendar.id === id) {
        found = calendar;
      }
    });

    return found || CalendarList[0];
  }

  function findCalendarByName(name) {
    var found;

    CalendarList.forEach(function(calendar) {
      if (calendar.name.toLowerCase() === name.toLowerCase()) {
        found = calendar;
      }
    });

    return found || null;
  }

  function hexToRGBA(hex) {
    var radix = 16;
    var r = parseInt(hex.slice(1, 3), radix),
      g = parseInt(hex.slice(3, 5), radix),
      b = parseInt(hex.slice(5, 7), radix),
      a = parseInt(hex.slice(7, 9), radix) / 255 || 1;
    var rgba = 'rgba(' + r + ', ' + g + ', ' + b + ', ' + a + ')';

    return rgba;
  }

  /* Schedules */

  var ScheduleList = [];

  var SCHEDULE_CATEGORY = [
    'milestone',
    'task'
  ];

  var chance = new Chance();

  function ScheduleInfo() {
    this.id = null;
    this.calendarId = null;

    this.title = null;
    this.body = null;
    this.isAllDay = false;
    this.start = null;
    this.end = null;
    this.category = '';
    this.dueDateClass = '';

    this.color = null;
    this.bgColor = null;
    this.dragBgColor = null;
    this.borderColor = null;
    this.customStyle = '';

    this.isFocused = false;
    this.isPending = false;
    this.isVisible = true;
    this.isReadOnly = false;
    this.goingDuration = 0;
    this.comingDuration = 0;
    this.recurrenceRule = '';
    this.state = '';

    this.raw = {
      memo: '',
      hasToOrCc: false,
      hasRecurrenceRule: false,
      location: null,
      class: 'public', // or 'private'
      creator: {
        name: '',
        avatar: '',
        company: '',
        email: '',
        phone: ''
      }
    };
  }

  function getAllSchedules(viewName, renderStart, renderEnd) {
    ScheduleList = [];
    CalendarList.forEach(function(calendar) {
      var schedule = new ScheduleInfo();
      schedule.id = "";
      schedule.calendarId = calendar.id;

      schedule.title = "";
      schedule.body = "";
      schedule.isReadOnly = false;
      schedule.category = "";
      schedule.start = "";
      schedule.end = "";

      schedule.isPrivate = false;
      schedule.location = "";
      schedule.attendees = "";
      schedule.recurrenceRule = ""; //'repeated events';
      schedule.state = ""; // Free, Busy
      schedule.color = calendar.color;
      schedule.bgColor = calendar.bgColor;
      schedule.dragBgColor = calendar.dragBgColor;
      schedule.borderColor = calendar.borderColor;

      if (schedule.category === 'milestone') {
        schedule.color = schedule.bgColor;
        schedule.bgColor = 'transparent';
        schedule.dragBgColor = 'transparent';
        schedule.borderColor = 'transparent';
      }

      /*schedule.raw.memo = chance.sentence();
      schedule.raw.creator.name = chance.name();
      schedule.raw.creator.avatar = chance.avatar();
      schedule.raw.creator.company = chance.company();
      schedule.raw.creator.email = chance.email();
      schedule.raw.creator.phone = chance.phone();*/

      /*if (chance.bool({ likelihood: 20 })) {
        var travelTime = chance.minute();
        schedule.goingDuration = travelTime;
        schedule.comingDuration = travelTime;
      }*/

      ScheduleList.push(schedule);
    });
  }

  function generateSchedule(scheduleData) {
    if (!scheduleData.title) {
      return;
    }

    var schedule = new ScheduleInfo();
    schedule.id = scheduleData.id;
    schedule.calendarId = scheduleData.calendar.id;
    schedule.title = scheduleData.title;
    schedule.isAllDay = scheduleData.isAllDay;
    schedule.start = scheduleData.start;
    schedule.end = scheduleData.end;
    schedule.category = scheduleData.isAllDay ? 'allday' : 'time';
    schedule.dueDateClass = '';
    schedule.color = scheduleData.calendar.color;
    schedule.bgColor = scheduleData.calendar.bgColor;
    schedule.dragBgColor = scheduleData.calendar.bgColor;
    schedule.borderColor = scheduleData.calendar.borderColor;
    schedule.raw = {
      location: scheduleData.location
    };
    schedule.state = 'Busy';
    return schedule;
  }

  (function() {
    var _calendar;
    $.ajax({
      url: "/calendars",
      type: "GET",
      dataType: "json",
      success: function(response) {
        if (response && response.length > 0) {
          response.forEach(function(calendar) {
            _calendar = new CalendarInfo();
            _calendar.id = String(calendar.id);
            _calendar.name = calendar.name;
            _calendar.color = calendar.color;
            _calendar.bgColor = calendar.bg_color;
            _calendar.dragBgColor = calendar.drag_bg_color;
            _calendar.borderColor = calendar.border_color;
            addCalendar(_calendar);
            calendar.calendar_schedules.forEach(function(calendar_schedule) {
              if (calendar_schedule) {
                ScheduleList.push(generateSchedule({ id: calendar_schedule.id, title: calendar_schedule.title ? calendar_schedule.title : "Untitled", location: calendar_schedule.location, isAllDay: calendar_schedule.is_all_day, start: calendar_schedule.start_date, end: calendar_schedule.end_date, calendar: _calendar }));
              }
            });
          })
        }
        $("body").trigger("calendar-load");
      },
      error: function() {

      }
    })
    $.ajax({
      url: "/theme_schedulers/new",
      type: "GET",
      dataType: "json",
      success: function(response) {
        $("#theme-scheduler-banner-enable").prop("checked", response.configs.current.banner_enable);
        $("#theme-scheduler-promotional-ticker-text").val(response.configs.current.promotional_ticker_text_1);
        $('.fileinput[data-target="image_banner.png"] + label').css("background-image", `url(${response.assets["image_banner.png"]})`);
        $('.fileinput[data-target="image_banner_mobile.png"] + label').css("background-image", `url(${response.assets["image_banner_mobile.png"]})`);
        $('.fileinput[data-target="image_slider1.png"] + label').css("background-image", `url(${response.assets["image_slider1.png"]})`);
        $('.fileinput[data-target="image_slider_mobile1.png"] + label').css("background-image", `url(${response.assets["image_slider_mobile1.png"]})`);
        $('.fileinput[data-target="homepage_seasonal_1.jpg"] + label').css("background-image", `url(${response.assets["homepage_seasonal_1.jpg"]})`);
        $('.fileinput[data-target="homepage_seasonal_2.jpg"] + label').css("background-image", `url(${response.assets["homepage_seasonal_2.jpg"]})`);
        $("#theme-scheduler-cart-free-gift-enable").prop("checked", response.configs.current.cart_free_gift_enable);
        $("#theme-scheduler-cart-free-gift-handle").val(response.configs.current.cart_free_gift_handle);
        $("#theme-scheduler-cart-free-gift-threshold").val(response.configs.current.cart_free_gift_threshold);
      }
    })
  })();

  const init = () => {
    var cal, resizeThrottled;
    var useCreationPopup = false;
    var useDetailPopup = false;
    var selectedCalendar;
    var selectedSchedule = {};
    var startDate, endDate;

    $("[data-toggle='modal-edit-schedule']").click(function() {
      $('#modal-edit-schedule').modal('show');
    });

    $('#daterangepicker-theme-schedule').on('apply.daterangepicker', function(ev, picker) {
      startDate = moment(picker.startDate).format("YYYY-MM-DDTHH:mm:ss");
      endDate = moment(picker.endDate).format("YYYY-MM-DDThh:mm:ss");
    });

    cal = new Calendar('#tui-calendar', {
      defaultView: 'month',
      useCreationPopup: useCreationPopup,
      useDetailPopup: useDetailPopup,
      calendars: CalendarList,
      template: {
        milestone: function(model) {
          return '<span class="calendar-font-icon ic-milestone-b"></span> <span style="background-color: ' + model.bgColor + '">' + model.title + '</span>';
        },
        allday: function(schedule) {
          return getTimeTemplate(schedule, true);
        },
        time: function(schedule) {
          return getTimeTemplate(schedule, false);
        }
      }
    });

    // event handlers

    $("#js-cancel-confirm").on("click", function() {
      $.ajax({
        url: "theme_schedulers/cancel",
        dataType: "json",
        method: "POST",
        beforeSend: getCSRFToken,
        data: { id: selectedSchedule.id }
      }).done(function(response) {
        cal.deleteSchedule(selectedSchedule.id, selectedSchedule.calendarId);
        notify("success", "Schedule has been canceled.");
      });
    });

    $("body").on("click", ".tui-full-calendar-weekday-schedule", function(e) {
      $("#js-cancel").removeClass("hide");
      e.stopPropagation();
    });

    $("body").bind("click", function(e) {
      if (!$(e.target).is("#js-cancel")) {
        $("#js-cancel").addClass("hide");
      }
    });

    cal.on({
      'clickMore': function(e) {

      },
      'clickSchedule': function(e) {
        console.log(e.schedule);
        //e.stopPropagation();
        selectedSchedule = { id: e.schedule.id, calendarId: e.schedule.calendarId };
      },
      'clickDayname': function(date) {

      },
      'beforeCreateSchedule': function(e) {
        e.guide.clearGuideElement();
      },
      'beforeUpdateSchedule': function(e) {
        var schedule = e.schedule;
        var changes = e.changes;

        cal.updateSchedule(schedule.id, schedule.calendarId, changes);
        refreshScheduleVisibility();
      },
      'beforeDeleteSchedule': function(e) {
        cal.deleteSchedule(e.schedule.id, e.schedule.calendarId);
      },
      'afterRenderSchedule': function(e) {
        var schedule = e.schedule;
        // var element = cal.getElement(schedule.id, schedule.calendarId);
      },
      'clickTimezonesCollapseBtn': function(timezonesCollapsed) {
        if (timezonesCollapsed) {
          cal.setTheme({
            'week.daygridLeft.width': '77px',
            'week.timegridLeft.width': '77px'
          });
        } else {
          cal.setTheme({
            'week.daygridLeft.width': '60px',
            'week.timegridLeft.width': '60px'
          });
        }
        return true;
      }
    });

    /**
     * Get time template for time and all-day
     * @param {Schedule} schedule - schedule
     * @param {boolean} isAllDay - isAllDay or hasMultiDates
     * @returns {string}
     */
    function getTimeTemplate(schedule, isAllDay) {
      var html = [];
      var start = moment(schedule.start.toUTCString());
      if (!isAllDay) {
        html.push('<strong>' + start.format('HH:mm') + '</strong> ');
      }
      if (schedule.isPrivate) {
        html.push('<span class="calendar-font-icon ic-lock-b"></span>');
        html.push(' Private');
      } else {
        if (schedule.isReadOnly) {
          html.push('<span class="calendar-font-icon ic-readonly-b"></span>');
        } else if (schedule.recurrenceRule) {
          html.push('<span class="calendar-font-icon ic-repeat-b"></span>');
        } else if (schedule.attendees.length) {
          html.push('<span class="calendar-font-icon ic-user-b"></span>');
        } else if (schedule.location) {
          html.push('<span class="calendar-font-icon ic-location-b"></span>');
        }
        html.push(' ' + schedule.title);
      }
      return html.join('');
    }

    /**
     * A listener for click the menu
     * @param {Event} e - click event
     */
    function onClickMenu(e) {
      var target = $(e.target).closest('a[role="menuitem"]')[0];
      var action = getDataAction(target);
      var options = cal.getOptions();
      var viewName = '';

      switch (action) {
        case 'toggle-daily':
          viewName = 'day';
          break;
        case 'toggle-weekly':
          viewName = 'week';
          break;
        case 'toggle-monthly':
          options.month.visibleWeeksCount = 0;
          viewName = 'month';
          break;
        case 'toggle-weeks2':
          options.month.visibleWeeksCount = 2;
          viewName = 'month';
          break;
        case 'toggle-weeks3':
          options.month.visibleWeeksCount = 3;
          viewName = 'month';
          break;
        case 'toggle-narrow-weekend':
          options.month.narrowWeekend = !options.month.narrowWeekend;
          options.week.narrowWeekend = !options.week.narrowWeekend;
          viewName = cal.getViewName();

          target.querySelector('input').checked = options.month.narrowWeekend;
          break;
        case 'toggle-start-day-1':
          options.month.startDayOfWeek = options.month.startDayOfWeek ? 0 : 1;
          options.week.startDayOfWeek = options.week.startDayOfWeek ? 0 : 1;
          viewName = cal.getViewName();

          target.querySelector('input').checked = options.month.startDayOfWeek;
          break;
        case 'toggle-workweek':
          options.month.workweek = !options.month.workweek;
          options.week.workweek = !options.week.workweek;
          viewName = cal.getViewName();

          target.querySelector('input').checked = !options.month.workweek;
          break;
        default:
          break;
      }

      cal.setOptions(options, true);
      cal.changeView(viewName, true);

      setDropdownCalendarType();
      setRenderRangeText();
      loadSchedules();
    }

    function onClickNavi(e) {
      var action = getDataAction(e.target);

      switch (action) {
        case 'move-prev':
          cal.prev();
          break;
        case 'move-next':
          cal.next();
          break;
        case 'move-today':
          cal.today();
          break;
        default:
          return;
      }

      setRenderRangeText();
      loadSchedules();
    }

    function createThemeSchedule(calendarSchedules) {
      $.ajax({
        url: "/theme_schedulers",
        type: "POST",
        dataType: "json",
        beforeSend: getCSRFToken,
        data: {
          start_at_time: calendarSchedules.start_date,
          end_at_time: calendarSchedules.end_date,
          promotional_ticker_text_1: $("#theme-scheduler-promotional-ticker-text").val(),
          banner_enable: $("#theme-scheduler-banner-enable").is(":checked"),
          "image_banner.png": $(`[data-asset="image_banner.png"]`).val(),
          "image_banner_mobile.png": $(`[data-asset="image_banner_mobile.png"]`).val(),
          "image_slider1.png": $(`[data-asset="image_slider1.png"]`).val(),
          "image_slider_mobile1.png": $(`[data-asset="image_slider_mobile1.png"]`).val(),
          "homepage_seasonal_1.jpg": $(`[data-asset="homepage_seasonal_1.jpg"]`).val(),
          "homepage_seasonal_2.jpg": $(`[data-asset="homepage_seasonal_2.jpg"]`).val(),
          cart_free_gift_enable: $("#theme-scheduler-cart-free-gift-enable").is(":checked"),
          cart_free_gift_handle: $("#theme-scheduler-cart-free-gift-handle").val(),
          cart_free_gift_threshold: $("#theme-scheduler-cart-free-gift-threshold").val()
        },
        success: function() {
          notify("success", "Schedule created successfully!");
        },
        error: function() {
          notify("error", "Unable to create schedule!");
        }
      })
    }

    function onSaveSchedule(e) {
      e.preventDefault();
      var title, calendar, id;
      const location = "";
      const isAllDay = true;
      const start = startDate;
      const end = endDate;
      switch ($(this).data("calendar")) {
        case "Theme Scheduler":
          title = $("#schedule-theme-scheduler-title").val();
          calendar = findCalendarByName("Theme Scheduler");
          const calendarSchedules = { title: title, location: location, is_all_day: isAllDay, start_date: start, end_date: end, is_pending: true, is_visible: true, is_complete: false, calendar_id: calendar.id };

          $.ajax({
            url: "/calendar_schedules",
            type: "POST",
            dataType: "json",
            "beforeSend": getCSRFToken,
            data: { calendar_schedules: calendarSchedules },
            success: function(response) {
              id = response.id;
              createThemeSchedule(calendarSchedules);
            },
            error: function(response) {
              notify("error", "Unable to create schedule!")
            }
          })
          break;
      }

      var schedule = generateSchedule({ id: id, title: title, location: location, isAllDay: isAllDay, start: start, end: end, calendar: calendar });
      $("body").one("pane-toggle", function() {
        cal.createSchedules([schedule]);
      });
    }

    function onChangeNewScheduleCalendar(e) {
      var target = $(e.target).closest('a[role="menuitem"]')[0];
      var calendarId = getDataAction(target);
      changeNewScheduleCalendar(calendarId);
    }

    function changeNewScheduleCalendar(calendarId) {
      var calendarNameElement = document.getElementById('calendarName');
      var calendar = findCalendar(calendarId);
      var html = [];

      html.push('<span class="calendar-bar" style="background-color: ' + calendar.bgColor + '; border-color:' + calendar.borderColor + ';"></span>');
      html.push('<span class="calendar-name">' + calendar.name + '</span>');

      calendarNameElement.innerHTML = html.join('');

      selectedCalendar = calendar;
    }

    function onAddCalendar(e) {
      e.preventDefault();
      const calendar = {
        name: $("#calendar-name").val(),
        color: $('input[type="color"]').filter('[data-id="text-color"]').val(),
        bg_color: $('input[type="color"]').filter('[data-id="background-color"]').val(),
        drag_bg_color: $('input[type="color"]').filter('[data-id="drag-color"]').val(),
        border_color: $('input[type="color"]').filter('[data-id="border-color"]').val(),
      };
      $.ajax({
        url: "/calendars",
        type: "POST",
        dataType: "json",
        "beforeSend": getCSRFToken,
        data: { calendar: calendar },
        success: function(response) {
          const _calendar = new CalendarInfo();
          _calendar.id = String(response.id);
          _calendar.name = response.name;
          _calendar.color = response.color;
          _calendar.bgColor = response.bg_color;
          _calendar.dragBgColor = response.drag_bg_color;
          _calendar.borderColor = response.border_color;
          addCalendar(_calendar);
          refreshScheduleVisibility();
          renderCalendarList();
          $('#addNewAppModal').modal('hide');
          notify("success", "Calendar added successfully!");
        },
        error: function(response) {
          notify("error", "Unable to add calendar!")
        }
      })
    }

    function onChangeCalendars(e) {
      var calendarId = e.target.value;
      var checked = e.target.checked;
      var viewAll = document.querySelector('.lnb-calendars-item input');
      var calendarElements = Array.prototype.slice.call(document.querySelectorAll('#calendarList input'));
      var allCheckedCalendars = true;

      if (calendarId === 'all') {
        allCheckedCalendars = checked;

        calendarElements.forEach(function(input) {
          var span = input.parentNode;
          input.checked = checked;
          span.style.backgroundColor = checked ? span.style.borderColor : 'transparent';
        });

        CalendarList.forEach(function(calendar) {
          calendar.checked = checked;
        });
      } else {
        findCalendar(calendarId).checked = checked;

        allCheckedCalendars = calendarElements.every(function(input) {
          return input.checked;
        });

        if (allCheckedCalendars) {
          viewAll.checked = true;
        } else {
          viewAll.checked = false;
        }
      }

      refreshScheduleVisibility();
    }

    function refreshScheduleVisibility() {
      var calendarElements = Array.prototype.slice.call(document.querySelectorAll('#calendar-list input'));

      CalendarList.forEach(function(calendar) {
        cal.toggleSchedules(calendar.id, !calendar.checked, false);
      });

      cal.render();

      calendarElements.forEach(function(input) {
        var span = input.nextElementSibling;
        span.style.backgroundColor = input.checked ? span.style.borderColor : 'transparent';
      });
    }

    function setDropdownCalendarType() {
      var calendarTypeName = document.getElementById('calendarTypeName');
      var options = cal.getOptions();
      var type = cal.getViewName();
      var iconClassName;

      if (type === 'day') {
        type = 'Daily';
        iconClassName = 'calendar-icon ic_view_day';
      } else if (type === 'week') {
        type = 'Weekly';
        iconClassName = 'calendar-icon ic_view_week';
      } else if (options.month.visibleWeeksCount === 2) {
        type = '2 weeks';
        iconClassName = 'calendar-icon ic_view_week';
      } else if (options.month.visibleWeeksCount === 3) {
        type = '3 weeks';
        iconClassName = 'calendar-icon ic_view_week';
      } else {
        type = 'Monthly';
        iconClassName = 'calendar-icon ic_view_month';
      }

      calendarTypeName.innerHTML = type;
    }

    function setRenderRangeText() {
      var renderRange = document.getElementById('renderRange');
      var options = cal.getOptions();
      var viewName = cal.getViewName();
      var html = [];
      if (viewName === 'day') {
        html.push(moment(cal.getDate().getTime()).format('YYYY.MM.DD'));
      } else if (viewName === 'month' &&
        (!options.month.visibleWeeksCount || options.month.visibleWeeksCount > 4)) {
        html.push(moment(cal.getDate().getTime()).format('YYYY.MM'));
      } else {
        html.push(moment(cal.getDateRangeStart().getTime()).format('YYYY.MM.DD'));
        html.push(' ~ ');
        html.push(moment(cal.getDateRangeEnd().getTime()).format(' MM.DD'));
      }
      renderRange.innerHTML = html.join('');
    }

    function renderCalendarList() {
      var $calendarList = $("#calendar-list");
      var html = [];
      CalendarList.forEach(function(calendar) {
        html.push('<div class="lnb-calendars-item"><label>' +
          '<input type="checkbox" class="tui-full-calendar-checkbox-round" value="' + calendar.id + '" checked>' +
          '<span style="border-color: ' + calendar.borderColor + '; background-color: ' + calendar.borderColor + ';"></span>' +
          '<span>' + calendar.name + '</span>' +
          '</label></div>'
        );
      });
      $calendarList.html(html.join('\n'));
    }

    function loadSchedules() {
      cal.clear();
      //getAllSchedules(cal.getViewName(), cal.getDateRangeStart(), cal.getDateRangeEnd());
      if (ScheduleList.length > 0) {
        cal.createSchedules(ScheduleList);
        refreshScheduleVisibility();
      }
    }

    function setEventListener() {
      $('#menu-navi').on('click', onClickNavi);
      $('.dropdown-menu a[role="menuitem"]').on('click', onClickMenu);
      $('#lnb-calendars').on('change', onChangeCalendars);
      $("#add-calendar").on("click", onAddCalendar);
      $('[data-trigger="save-schedule"]').on('click', onSaveSchedule);
      $('#dropdownMenu-calendars-list').on('click', onChangeNewScheduleCalendar);
      $("body").on("calendar-load", renderCalendarList);
      $("body").on("calendar-load", loadSchedules);

      window.addEventListener('resize', resizeThrottled);
    }

    function getDataAction(target) {
      return target.dataset ? target.dataset.action : target.getAttribute('data-action');
    }

    resizeThrottled = function() {
      setTimeout(function() { // TODO: use lodash throttle
        cal.render();
      }, 50);
    }

    window.cal = cal;

    setDropdownCalendarType();
    setRenderRangeText();
    loadSchedules();
    setEventListener();
    renderCalendarList();

    var fileinputs = document.querySelectorAll('.fileinput');

    fileinputs.forEach(function(fileinput) {
      fileinput.addEventListener('change', function(evt) {
        if (evt.target.files.length > 0) {
          getBase64(evt.target.files[0], function(base64) {
            $(evt.target).next("label").css("background-image", `url(${base64})`);
            $(`[data-asset="${$(evt.target).data('target')}"]`).val(base64.replace(/^data:image\/.+;base64,/i, ''));
          });
        }
      });
    });

    function getBase64(file, callback) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function() {
        const base64 = reader.result;
        callback(base64);
      };
      reader.onerror = function(error) {
        console.log('Error: ', error);
      };
    }

  };
  return init;
}