var Tabulator = require('tabulator-tables');
export default function products() {
  'use strict';

  // converts the vendor array into an object that has the following format (key and value the same where key/value is name of vendor):
  // { "Vendor A": "Vendor A", "Vendor B": "Vendor B", ... }
  const arrayToVendorObj = (array, key) => {
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item[key]
      }
    }, {});
  };

  class Currency {
    constructor(currency) {
      this.currency = currency;
      this.val;
      this.delimited;
    }

    from(val) {
      const valString = val.toString().trim();
      if (valString && valString.length) {
        const parts = valString.replace(/,/g, "").split(".");
        this.delimited = parts[1] && parts[1].length ? parts[1].padEnd(2, "0").slice(0, 2) : "00"
        this.val = parts[0];
      } else {
        this.delimited = "";
        this.val = "";
      }
      return this;
    }

    format(currency) {
      if (!this.val.length) return "";
      if (currency) {
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: currency,
        });
        return formatter.format([this.val, this.delimited].join("."));
      } else {
        return parseInt(this.val + this.delimited);
      }
    }
  }

  const money = new Currency();

  var addProduct = function(callback) {
    const product = {
      title: $("#productTitle").val(),
      vendor: $("#productVendor").val(),
      product_type: $("#productType").val(),
      application: $("#productApplication").val(),
      texture: $("#productTexture").val(),
      lighting: $("#productLighting").val(),
      size: $("#productSize").val(),
      ingredients: $("#productIngredients").val(),
      tags: $("#productTags").val(),
      dist_price: money.from($("#productDistPrice").val()).format(),
      wholesale_price: money.from($("#productWholesalePrice").val()).format(),
      retail_price: money.from($("#productRetailPrice").val()).format(),
      product_variants_attributes: [{
        sku: $("#productSKU").val()
      }]
    };
    $.ajax({
      dataType: "json",
      url: "/products",
      type: "POST",
      "beforeSend": function(xhr) { xhr.setRequestHeader("X-CSRF-Token", $('meta[name="csrf-token"]').attr("content")) },
      data: { product: product },
      success: function(response) {
        if (response && response.id) {
          $(".page-content-wrapper").pgNotification({
            style: "bar",
            message: "Success! Product created.",
            position: "top",
            timeout: 0,
            type: "success"
          }).show();
          callback(response);
        }
      }
    })
  };

  let changes = {};

  var saveChanges = function() {
    setTimeout(function() {
      $.ajax({
        dataType: "json",
        url: "/product_variants/bulk_update",
        type: "PUT",
        "beforeSend": function(xhr) { xhr.setRequestHeader("X-CSRF-Token", $('meta[name="csrf-token"]').attr("content")) },
        data: { product_variants: JSON.stringify(changes) },
        success: function(response) {
          if (response) {
            $(".page-content-wrapper").pgNotification({
              style: "bar",
              message: "Success! Products updated.",
              position: "top",
              timeout: 0,
              type: "success"
            }).show();
            changes = {};
          }
        }
      })
    }, 0);
  };

  const populateVendorsProductCreateForm = (vendors) => {
    const options = vendors.reduce((html, vendor) => {
      return `${html}<option value="${vendor.id}">${vendor.name}</option>`
    }, '');
    $("#productVendor").html(options);
  };

  const renderTable = (tableData, preRender, callback) => {
    preRender(function(vendors) {
      //define table
      let table = new Tabulator("#productTable", {
        layout: "fitDataStretch",
        data: tableData,
        height: "calc(100vh - 65px)",
        columns: [
          { title: "Title", field: "title", width: 250, frozen: true, editor: "input" },
          { title: "Vendor", field: "vendor", width: 200, editor: "select", editorParams: { values: arrayToVendorObj(vendors, 'name') } },
          { title: "SKU", field: "sku", width: 150 },
          { title: "Type", field: "product_type", editor: "select", editorParams: { values: { "Nail Polish": "Nail Polish", "Nail Care": "Nail Care", "Nail Accessories": "Nail Accessories", "Gift Card": "Gift Card", "Supplies": "Supplies", "Bottle Component": "Bottle Component" } } },
          { title: "Dist Price", field: "dist_price" },
          { title: "Wholesale Price", field: "wholesale_price" },
          { title: "Retail Price", field: "retail_price" },
          { title: "id", field: "id", visible: false }
        ],
        cellEdited: function(cell) {
          const data = cell.getRow().getData();
          changes[data.id] = {
            id: data.id,
            title: data.title,
            vendor: data.vendor,
            sku: data.sku,
            product_type: data.product_type,
            dist_price: money.from(data.dist_price).format(),
            wholesale_price: money.from(data.wholesale_price).format(),
            retail_price: money.from(data.retail_price).format()
          };
        }
      });
      callback(table);
    });
  };

  const getVendors = (callback) => {
    $.ajax({
      dataType: "json",
      url: "/suppliers",
      type: "GET",
      "beforeSend": function(xhr) { xhr.setRequestHeader("X-CSRF-Token", $('meta[name="csrf-token"]').attr("content")) },
      success: function(response) {
        populateVendorsProductCreateForm(response);
        if (callback) callback(response);
      }
    });
  };

  // Initialize datatable with ability to add rows dynamically
  const init = () => {
    $.ajax({
      dataType: "json",
      url: "/products",
      type: "GET",
      "beforeSend": function(xhr) { xhr.setRequestHeader("X-CSRF-Token", $('meta[name="csrf-token"]').attr("content")) },
      success: function(response) {
        const tableData = response.map((product) => {
          return {
            title: product.title,
            vendor: product.vendor,
            sku: product.product_variants && product.product_variants.length ? product.product_variants[0].sku : '',
            product_type: product.product_type,
            dist_price: (product.dist_price * 0.01).toFixed(2),
            wholesale_price: (product.wholesale_price * 0.01).toFixed(2),
            retail_price: (product.retail_price * 0.01).toFixed(2),
            id: product.product_variants && product.product_variants.length ? product.product_variants[0].id : ''
          }
        });

        renderTable(tableData, getVendors, function(table) {

          $('#show-modal').click(function() {
            $('#addNewAppModal').modal('show');
          });

          $('#add-product').click(function() {
            addProduct(function(product) {
              table.addRow({
                title: product.title,
                vendor: product.vendor,
                sku: product.product_variants[0].sku,
                product_type: product.product_type,
                application: product.application,
                texture: product.texture,
                lighting: product.lighting,
                size: product.size,
                ingredients: product.ingredients,
                tags: product.tags,
                dist_price: (product.dist_price).toFixed(2),
                wholesale_price: (product.wholesale_price).toFixed(2),
                retail_price: (product.retail_price).toFixed(2),
                id: product.id
              }, true);
            });
            $('#addNewAppModal').modal('hide');
          });

          $('#update-products').click(function() {
            saveChanges(changes);
          });
        });
      }
    });

  };

  return init;
}