require("../../plugins/serializejson");

export default function admin() {
  'use strict';
  const csrfToken = $('meta[name="csrf-token"]').attr("content");
  const getCSRFToken = function(xhr) { xhr.setRequestHeader("X-CSRF-Token", csrfToken) };
  const $pageContentWrapper = $(".page-content-wrapper");
  const notify = (type, message) => {
    $pageContentWrapper.pgNotification({
      style: "bar",
      message: message,
      position: "top",
      timeout: 0,
      type: type
    }).show();
  };
  const init = () => {
    $("#form-register").on("submit", function(e) {
      e.preventDefault();
      const serializedData = $(this).serializeJSON();
      $.ajax({
        type: "POST",
        dataType: "json",
        url: "/users",
        beforeSend: getCSRFToken,
        data: { registration: serializedData },
        success: function(response) {
          notify("success", "User was created successfully!");
        }
      })
    });
    $(".js-save-permissions").on("click", function(e) {
      e.preventDefault();
      const userId = $(this).data("user-id");
      let roles = [];
      $(`.${userId}checkboxes`).map(function() {
        if ($(this).is(":checked")) {
          roles.push($(this).data("role-name"));
        }
      });
      $.ajax({
        type: "PUT",
        dataType: "json",
        url: "/admin",
        beforeSend: getCSRFToken,
        data: { user_id: userId, roles: roles },
        success: function(response) {
          notify("success", "Permissions saved successfully!");
        }
      })
    });
  };
  return init;
}