require("../../plugins/serializejson");

export default function settings() {
  'use strict';
  const init = () => {
    $("#form-register").on("submit", function(e) {
      e.preventDefault();
      const serializedData = $(this).serializeJSON();
      $.ajax({
        type: "PUT",
        dataType: "json",
        url: "/users",
        data: { registration: serializedData },
        success: function(response) {
          $(".page-content-wrapper").pgNotification({
            style: "bar",
            message: "Success! Your account settings changes have been saved.",
            position: "top",
            timeout: 0,
            type: "success"
          }).show();
        },
        error: function() {
          $(".page-content-wrapper").pgNotification({
            style: "bar",
            message: "Error! Changes were not saved.",
            position: "top",
            timeout: 0,
            type: "error"
          }).show();
        }
      })
    })
  };
  return init;
}