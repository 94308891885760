export default class Currency {
  constructor(currency) {
    this.currency = currency;
    this.val;
    this.delimited;
  }

  from(val, fractional = false) {
    const valString = val.toString().trim();
    if (valString && valString.length) {
      const parts = valString.replace(/,/g, "").split(".");
      if (fractional) {
        this.delimited = parts[1] && parts[1].length ? parts[1].padEnd(4, "0").slice(0, 4) : "0000"
      } else {
        this.delimited = parts[1] && parts[1].length ? parts[1].padEnd(2, "0").slice(0, 2) : "00"
      }
      this.val = parts[0];
    } else {
      this.delimited = "";
      this.val = "";
    }
    return this;
  }

  format(currency) {
    if (!this.val.length) return "";
    if (currency) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
      });
      return formatter.format([this.val, this.delimited].join("."));
    } else {
      return parseInt(this.val + this.delimited);
    }
  }
}