import methods from './shipping/methods';
import mappings from './shipping/mappings';
import products from './products/index';
import purchase_orders from './purchase_orders/index';
import dashboard from './dashboard/show';
import admin from './admin/index';
import settings from './users/edit';
import calendars from './calendars/index';
import customer_central from './customer_central/index';

export default function load(widget) {
  let _init;
  switch (widget) {
    case 'methods':
      _init = methods();
      break;
    case 'mappings':
      _init = mappings();
      break;
    case 'products':
      _init = products();
      break;
    case 'purchase_orders':
      _init = purchase_orders();
      break;
    case 'dashboard':
      _init = dashboard();
      break;
    case 'admin':
      _init = admin();
      break;
    case 'settings':
      _init = settings();
      break;
    case 'calendars':
      _init = calendars();
      break;
    case 'customer_central':
      _init = customer_central();
      break;
  }
  return _init;
}