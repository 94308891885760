var Tabulator = require('tabulator-tables');
export default function customer_central() {
  'use strict';

  // Helpers

  const csrfToken = $('meta[name="csrf-token"]').attr("content");
  const getCSRFToken = function(xhr) { xhr.setRequestHeader("X-CSRF-Token", csrfToken) };
  const $pageContentWrapper = $(".page-content-wrapper");
  const notify = (type, message) => {
    $pageContentWrapper.pgNotification({
      style: "bar",
      message: message,
      position: "top",
      timeout: 0,
      type: type
    }).show();
  };

  const arrayToProductVariantsObj = (array, key) => {
    return array.reduce((obj, item) => {
      if (item[key].length > 0) {
        return {
          ...obj,
          [`"${item[key]}"`]: `${item['title']} - ${item[key]}`
        }
      }
    }, {});
  };

  const getProductVariants = (callback) => {
    $.ajax({
      dataType: "json",
      url: "/product_variants",
      type: "GET",
      "beforeSend": getCSRFToken,
      success: function(response) {
        if (callback) callback(response);
      }
    });
  };

  // cache HTML elements
  
  const $body = $("body");

  // search result
  const $jsSearchOrder = $("#js-search-order");
  const $searchOrderStorefront = $("#search-order-storefront");
  const $searchOrderNumber = $("#search-order-number");
  const $orderResult = $("#order-result");
  const $replacementOrders = $("#replacement-orders");
  const $replacementOrderReasons = $("#replacement-order-reasons");

  // generic
  const $orderResultNumber = $(".order-result-number");
  const $orderResultShipTitle = $(".order-result-ship-title");
  const $orderResultCarrier =  $(".order-result-carrier");
  const $orderResultTracking =  $(".order-result-tracking");
  const $orderResultLineQty =  $("#order-result-line-qty");
  const $orderResultTotal =  $(".order-result-total");
  const $orderResultCurrency =  $("#order-result-currency");
  const $orderResultName =  $("#order-result-name");
  const $orderResultCreatedDate =  $(".order-result-created-date");
  const $orderResultDateShipped =  $("#order-result-date-shipped");

  // order form
  const $addressFirstName = $("#order-first-name");
  const $addressLastName = $("#order-last-name");
  const $addressLine1 = $("#order-line1");
  const $addressLine2 = $("#order-line2");
  const $addressCity = $("#order-city");
  const $addressCountry = $("#order-country");
  const $addressState = $("#order-state");
  const $addressZip = $("#order-zip");
  const $shippingInfoForm = $(".form-shipping-info");
  const $orderLineItemsTable = $("#orderLineItemsTable");
  const $addOrderLineItem = $("#js-add-order-line-item");

  // order actions & summary
  const $orderResultStatus = $(".order-result-status");
  const $orderShipTitle = $("#order-ship-title");
  const $orderReasonsShipping = $("#order-reasons-shipping");
  const $orderActionsSummary = $("#order-actions-summary");
  const $actionDropdown = $("#action-dropdown");
  const $jsExpedite = $("#js-expedite");
  const $jsSaveOrder = $("#js-save-order");
  const actionSuspendElem = '<a id="js-order-suspend" class="dropdown-item" href="#">Suspend</a>';
  const actionUnsuspendElem = '<a id="js-order-unsuspend" class="dropdown-item" href="#">Unsuspend</a>';
  const actionCancelElem = '<a id="js-order-cancel" class="dropdown-item" href="#">Cancel</a>';
  
  // data store

  let store = {
    storefrontId: null,
    orderId: null,
    orderNumber: null,
    orderLineItemTableEditable: false,
    // order line items
    orderLineItemId: 0,
    orderLineItems: {},
    removeOrderLineItems: []
  };

  store.response = null;

  const clearStore = () => {
    store = {
      productVariants: store.productVariants,
      storefrontId: null,
      orderId: null,
      orderNumber: null,
      orderLineItemTableEditable: false,
      orderLineItemId: 0,
      orderLineItems: {},
      removeOrderLineItems: []
    };
  };

  const clearForm = () => {
    $addOrderLineItem.val('');
    $addressFirstName.val('');
    $addressLastName.val('');
    $addressLine1.val('');
    $addressLine2.val('');
    $addressCity.val('');
    $addressCountry.val('');
    $addressState.val('');
    $addressZip.val('');
  };

  const resetState = () => {
    store.orderLineItemId = 0;
    store.orderLineItems = {};
    store.removeOrderLineItems = [];
  };

  const toggleForm = (orderMethod) => {
    if (orderMethod === "PUT") {
      $orderReasonsShipping.addClass("hide");
      $orderActionsSummary.removeClass("hide");
      $addOrderLineItem.addClass("hide");
    } else {
      $orderReasonsShipping.removeClass("hide");
      $orderActionsSummary.addClass("hide");
      $addOrderLineItem.removeClass("hide");
    }
  };

  // Toggle Sections
  $('[data-toggle="section"]').on("click", function(e) {
    e.preventDefault();
    resetState();
    const target = $(this).data("target");
    const method = $(this).data("method");
    if (target === "#tab-create-edit-order") {
      store.orderMethod = method.toUpperCase();
      if (store.response) { 
        const is_replacement_order = store.orderMethod === "POST" ? true : false;
        renderOrder(store.response.original_order, is_replacement_order); 
      }
      toggleForm(store.orderMethod);
    }
    $(".tab-pane.active").removeClass("active");
    $(target).addClass("active");
  });

  const getOrderSummary = (order) => {
    const id = order.database["id"];
    const shipStatus = order.ship_status.toLowerCase();
    const shipStage = order.ship_stage.toLowerCase();
    const status = shipStatus !== "valid" && shipStatus !== "cancelled" ? shipStatus : shipStage;
    const trackingNumber = order.remote_order["CarrierTrackingNumber"] ? order.remote_order["CarrierTrackingNumber"] : '<span class="text-warning">pending</span>';
    const trackingURL = order.remote_order["CarrierTrackingNumber"] && order.remote_order["CarrierTrackingURL"] && order.remote_order["CarrierTrackingURL"].length > 0 ? `${order.remote_order["CarrierTrackingURL"]}${trackingNumber}` : 'javascript:void()';
    const shipmentId = order.remote_order["ShipmentId"];
    const dateDueOut = order.remote_order["DateDueOut"];
    const carrierId = order.remote_order["CarrierId"];
    const address = {
      line1: order.remote_order["ShipAddress"][0]["Line1"] ? order.remote_order["ShipAddress"][0]["Line1"] : '',
      line2: order.remote_order["ShipAddress"][0]["Line2"] ? order.remote_order["ShipAddress"][0]["Line2"] : '',
      city: order.remote_order["ShipAddress"][0]["City"] ? order.remote_order["ShipAddress"][0]["City"] : '',
      country: order.remote_order["ShipAddress"][0]["Country"] ? order.remote_order["ShipAddress"][0]["Country"] : '',
      state: order.remote_order["ShipAddress"][0]["State"] ? order.remote_order["ShipAddress"][0]["State"] : '',
      zip: order.remote_order["ShipAddress"][0]["Postcode"] ? order.remote_order["ShipAddress"][0]["Postcode"] : ''
    };
    const dateClosed = order.remote_order["DateClosed"];
    const lineItems = order.remote_order["ShipmentLines"];
    const lineQty = order.remote_order["LineQty"];
    const shipTitle = order.database["ship_title"];
    const totalPrice = order.database["total_price"];
    const currency = order.database["currency"];
    const customer = {
      firstName: order.database["first_name"],
      lastName: order.database["last_name"]
    };
    const createdAt = order.database["created_at"];
    return {id, shipStatus, shipStage, status, trackingNumber, trackingURL, shipmentId, dateDueOut, carrierId, address, dateClosed, lineItems, lineQty, shipTitle, totalPrice, currency, customer, createdAt};
  };

  const getStatusColor = (shipStage, shipStatus) => {
    let color = "warning";

    if (shipStage == "fulfilled") {
      color = "success";
    } else if (shipStage == "due out") {
      color = "complete";
    } else if (shipStage == "shipped") {
      color = "info";
    } else if (shipStage == "canceled") {
      color = "danger";
    }

    if (shipStatus !== "valid") {
      color = "danger";
    }
    return color;
  };

  const setShippingInfoDisabled = (isDisabled) => {
    $addressFirstName.prop("disabled", isDisabled);
    $addressLastName.prop("disabled", isDisabled);
    $addressLine1.prop("disabled", isDisabled);
    $addressLine2.prop("disabled", isDisabled);
    $addressCity.prop("disabled", isDisabled);
    $addressCountry.prop("disabled", isDisabled);
    $addressState.prop("disabled", isDisabled);
    $addressZip.prop("disabled", isDisabled);

    if (isDisabled) {
      $shippingInfoForm.addClass("disabled");
    } else {
      $shippingInfoForm.removeClass("disabled");
    }
  };

  const setShipTitleDisabled = (isDisabled, canBeExpedited) => {
    if (isDisabled) {
      $jsExpedite.html('');
    } else {
      if (canBeExpedited) {
        $jsExpedite.html('<button class="btn-warning btn btn-sm" style="padding: 5px;margin-top: 10px;color: #000;"><i class="fa fa-bolt"></i> Expedite</button>');
      } else {
        $jsExpedite.html('');
      }
    }
  };

  const renderReplacementOrders = (replacement_orders) => {
    $replacementOrders.html('');
    replacement_orders.forEach((replacement_order) => {
      const {id, shipStatus, shipStage, status, trackingNumber, trackingURL, shipmentId, dateDueOut, carrierId, address, dateClosed} = getOrderSummary(replacement_order);
      const color = getStatusColor(shipStage, shipStatus);

      const html = `
        <div class="company-stat-box m-t-15 active padding-20 bg-master-lightest">
        <div>
          <p class="company-name pull-left text-uppercase bold no-margin">
            <span class="fa fa-circle text-${color} fs-11"></span>${status}
          </p>
          <small class="hint-text m-l-10">${shipmentId}</small>
          <div class="clearfix"></div>
        </div>
        <div class="m-t-10">
          <p class="pull-left small hint-text no-margin p-t-5">${dateDueOut}</p>
          <div class="pull-right">
            <p class="small hint-text no-margin inline">${carrierId} <a target="_blank" href="${trackingURL}" class="order-result-tracking text-primary">${trackingNumber}</a></p>
            <button class="btn label label-important p-t-5 m-l-5 p-b-5 inline fs-12 js-cancel-replacement-order" data-replacement-order-id="${id}">Cancel</button>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
      `;
      $replacementOrders.append(html);
    });
  };

  const renderOrderLineItemsTable = (tableData, productVariants, callback) => {
    const defaultQty = 1;
    const skus = arrayToProductVariantsObj(productVariants, 'sku');
    const placeHolder = function(cell, formatterParams) {
      const cellValue = cell.getValue();
      if (cellValue === "") {
        return "Start typing SKU or name..."
      } else {
        return cellValue;
      }
    };
    const deleteCellFormatter = function(cell, formattterParams) {
      const style = store.orderLineItemTableEditable ? '' : 'style="color: #eee; cursor: auto;"';
      return `<i class="fa fa-trash" ${style}></i>`;
    };
    const removeRow = function(cell) {
      const sku = cell.getRow().getData().sku;
      if (store.orderLineItems[sku]) {
        if (store.orderMethod === "PUT") {
          store.removeOrderLineItems.push(store.orderLineItems[sku].sku)
        } 
        delete store.orderLineItems[sku];
      }
      cell.getRow().delete();
    };
    //define table
    let table = new Tabulator("#orderLineItemsTable", {
      layout: "fitColumns",
      placeholder: "No Data Available",
      data: tableData,
      columns: [{
          title: "SKU",
          field: "sku",
          editor: store.orderLineItemTableEditable ? "autocomplete" : false,
          editorParams: {
            showListOnEmpty: true,
            freetext: false, //allow the user to set the value of the cell to a free text entry
            allowEmpty: false,
            values: skus,
            sortValuesList: "asc", //if creating a list of values from values:true then choose how it should be sorted
          },
          formatter: placeHolder,
          cellEdited: function(cell) {
            const sku = cell.getRow().getData().sku;
            if (!store.orderLineItems[sku]) { store.orderLineItems[sku] = {}; }
            store.orderLineItems[sku].sku = cell.getValue();
          }
        },
        {
          title: "Quantity",
          field: "quantity",
          editor: store.orderLineItemTableEditable ? "input" : false,
          headerSort: false,
          cellEdited: function(cell) {
            const sku = cell.getRow().getData().sku;
            if (!store.orderLineItems[sku]) { store.orderLineItems[sku] = { quantity: defaultQty }; }
            store.orderLineItems[sku].quantity = parseInt(cell.getValue());
          }
        },
        { formatter: deleteCellFormatter, width: 50, align: "right", headerSort: false, cellClick: function(e, cell) { if (store.orderLineItemTableEditable) removeRow(cell) } },
        { title: "id", field: "id", visible: false }
      ]
    });
    $addOrderLineItem.click(function(e) {
      e.preventDefault();
      store.orderLineItemId = store.orderLineItemId + 1;
      table.addRow({
        id: store.orderLineItemId,
        sku: "",
        quantity: defaultQty
      });
    });
  };

  const loadOrder = () => {
    const errorMsg = "Sorry, there's no order that matches your search query.";
    $jsSearchOrder.prop("disabled", true);
    store.storefrontId = $searchOrderStorefront.val();
    store.orderNumber = $searchOrderNumber.val().trim();
    $.ajax({
      url: "/orders/poll",
      dataType: "json",
      type: "GET",
      beforeSend: getCSRFToken,
      data: { storefront_id: store.storefrontId, order_number: store.orderNumber }
    }).done(function(response) {
      if (response) {
        store.response = response;
        const original_order = response.original_order;
        const replacement_orders = response.replacement_orders;
        //renderOrder(original_order);
        renderReplacementOrders(replacement_orders);
        $orderResult.removeClass("hidden");
        $jsSearchOrder.prop("disabled", false);
      } else {
        store.response = null;
        notify("error", errorMsg);
        $jsSearchOrder.prop("disabled", false);
      }
    }).fail(function() {
      store.response = null;
      notify("error", errorMsg);
      $jsSearchOrder.prop("disabled", false);
    });
  };

  const getOrderParams = () => {
    let orderLineItems = Object.values(store.orderLineItems).map((orderLineItem) => {
      if (orderLineItem && orderLineItem.sku && orderLineItem.quantity) {
        let temp = {
          sku: orderLineItem.sku,
          quantity: orderLineItem.quantity
        };
        return temp;
      }
    });
    store.removeOrderLineItems.forEach(function(sku) {
      orderLineItems.push({ sku: sku, _destroy: 1 });
    });
    return {
      id: store.orderId,
      first_name: $addressFirstName.val(),
      last_name: $addressLastName.val(),
      line1: $addressLine1.val(),
      line2: $addressLine2.val(),
      city: $addressCity.val(),
      country: $addressCountry.val(),
      state: $addressState.val(),
      zip: $addressZip.val(),
      ship_title: $orderShipTitle.text(),
      notes: "",
      order_line_items_attributes: orderLineItems
    };
  };

  const createReplacementOrder = () => {
    let orderParams = getOrderParams();
    const errorMsg = "Something went wrong. Replacement order did not save.";
    const successMsg = "Success! Replacement order created.";
    const reasons = $replacementOrderReasons.val();
    $.ajax({
      url: `/replacements`,
      dataType: "json",
      type: "POST",
      beforeSend: getCSRFToken,
      data: { storefront_id: store.storefrontId, order: orderParams, reasons: reasons }
    }).done(function(response) {
      if (response === "success") {
        notify("success", successMsg);
      } else {
        notify("error", errorMsg);
      }
    }).fail(function() {
      notify("error", "Something went wrong. Replacement order did not save.");
    });
  };

  const updateOrder = (type = "edit") => { // type can be "edit", "suspend", "unsuspend", "cancel"
    let orderParams = getOrderParams();
    $.ajax({
      url: `/orders/${store.orderId}`,
      dataType: "json",
      type: "PUT",
      beforeSend: getCSRFToken,
      data: { storefront_id: store.storefrontId, order: orderParams, type: type }
    }).done(function(response) {
      if (response === "success") {
        notify("success", "Success! Order saved.");
      } else {
        notify("error", "Something went wrong. Order did not save.");
      }
    }).fail(function() {
      notify("error", "Something went wrong. Order did not save.");
    });
  };

  const updateReplacementOrder = (replacementOrderId, type = "cancel") => {
    $.ajax({
      url: `/replacements/${replacementOrderId}`,
      dataType: "json",
      type: "PUT",
      beforeSend: getCSRFToken,
      data: { storefront_id: store.storefrontId, type: type }
    }).done(function(response) {
      if (response === "success") {
        notify("success", "Success! Order saved.");
      } else {
        notify("error", "Something went wrong. Order did not save.");
      }
    }).fail(function() {
      notify("error", "Something went wrong. Order did not save.");
    });
  };

  const renderOrder = (order, is_replacement_order) => {
    const {shipStatus, shipStage, status, trackingNumber, trackingURL, shipmentId, dateDueOut, carrierId, address, dateClosed, lineItems, lineQty, shipTitle, totalPrice, currency, customer, createdAt} = getOrderSummary(order);
    const color = getStatusColor(shipStage, shipStatus);
    const dateClosedHtml = dateClosed ? `${dateClosed.split("T")[0]}` : '<span class="text-danger">Not shipped</span>';
    const canBeExpedited = order.can_be_expedited;

    if (shipStatus !== "valid" && shipStatus !== "cancelled") {
      $orderResultStatus.html(`${shipStatus} <i class="fa fa-circle text-${color} fs-11"></i>`);
    } else {
      $orderResultStatus.html(`${shipStage} <i class="fa fa-circle text-${color} fs-11"></i>`);
    }
    $orderResultNumber.text(`${shipmentId}`);
    $orderResultShipTitle.text(`${shipTitle}`);
    $orderResultCarrier.text(`${carrierId}`);
    $orderResultTracking.html(trackingNumber).attr("href", trackingURL);
    $orderResultLineQty.text(`${lineQty}`);
    $orderResultTotal.text(`${totalPrice.toFixed(2)}`);
    $orderResultCurrency.text(`${currency}`);
    $orderResultName.text(`${customer.firstName} ${customer.lastName}`);
    $orderResultCreatedDate.text(`${createdAt.split("T")[0]}`);
    $orderResultDateShipped.html(dateClosedHtml);

    $addressFirstName.val(`${customer.firstName}`);
    $addressLastName.val(`${customer.lastName}`);
    $addressLine1.val(address.line1);
    $addressLine2.val(address.line2);
    $addressCity.val(address.city);
    $addressCountry.val(address.country);
    $addressState.val(address.state);
    $addressZip.val(address.zip);

    if (is_replacement_order) {
      setShippingInfoDisabled(false);
    }
    else {
      if (shipStatus !== "valid" && shipStatus !== "cancelled") {
        if (shipStatus === "suspended") {
          setShippingInfoDisabled(false);
          setShipTitleDisabled(false, canBeExpedited);
          $actionDropdown.html(`${actionUnsuspendElem}${actionCancelElem}`);
        } else if (shipStatus === "interface error") { // same as marshalled
          setShippingInfoDisabled(false);
          setShipTitleDisabled(false, canBeExpedited);
          $actionDropdown.html(`${actionSuspendElem}${actionCancelElem}`);
        } else {
          setShippingInfoDisabled(true);
          setShipTitleDisabled(true, canBeExpedited);
          $actionDropdown.html('');
        }
      } else { // ship status is either valid or cancelled
        if (shipStage === "due out") {
          setShippingInfoDisabled(false);
          setShipTitleDisabled(false, canBeExpedited);
          $actionDropdown.html(`${actionSuspendElem}${actionCancelElem}`);
        } else if (shipStage === "marshalled") { // same as interface error
          setShippingInfoDisabled(false);
          setShipTitleDisabled(false, canBeExpedited);
          $actionDropdown.html(`${actionSuspendElem}${actionCancelElem}`);
        } else if (shipStage === "allocated" || shipStage === "pack" || shipStage === "packed") {
          setShippingInfoDisabled(false);
          setShipTitleDisabled(false, canBeExpedited);
          $actionDropdown.html(`${actionCancelElem}`);
        } else {
          setShippingInfoDisabled(true);
          setShipTitleDisabled(true, canBeExpedited);
          $actionDropdown.html('');
        }
      }
    }

    store.orderId = order.database.id;

    const lineItemsData = lineItems.map((lineItem, index) => {
      const sku = lineItem["SKUId"];
      const qty = lineItem["QtyOrdered"];
      if (qty > 0) {
        store.orderLineItems[sku] = {
          sku: sku,
          title: "",
          quantity: qty
        };
        const cells = {
          sku: sku,
          title: "",
          quantity: qty,
          id: index
        }
        store.orderLineItemId = index;
        return cells;
      }
    });

    if (is_replacement_order || shipStage === "due out") {
      $orderLineItemsTable.removeClass("disabled");
      store.orderLineItemTableEditable = true;
    } else {
      $orderLineItemsTable.addClass("disabled");
      store.orderLineItemTableEditable = false;
    }

    renderOrderLineItemsTable(lineItemsData, store.productVariants);
  };

  const init = () => {
    $jsSearchOrder.on("click", function(e) {
      e.preventDefault();
      if (!store.productVariants) {
        getProductVariants(function(response) {
          store.productVariants = response;
          loadOrder();
        });
      } else {
        clearStore();
        clearForm();
        loadOrder();
      }
    });

    $searchOrderNumber.on('keypress', function(e) {
      var code = (e.keyCode ? e.keyCode : e.which);
      if (code == 13) { // Enter keycode
        if ($jsSearchOrder.prop("disabled", false)) {
          if (!store.productVariants) {
            getProductVariants(function(response) {
              store.productVariants = response;
              loadOrder();
            });
          } else {
            loadOrder();
          }
        }
      }
    });

    $body.on("click", ".js-cancel-replacement-order", function(e) {
      e.preventDefault();
      updateReplacementOrder($(this).data("replacement-order-id"), "cancel");
    });

    $body.on("click", "#js-order-suspend", function(e) {
      e.preventDefault();
      updateOrder("suspend");
    });

    $body.on("click", "#js-order-unsuspend", function(e) {
      e.preventDefault();
      updateOrder("unsuspend");
    });

    $body.on("click", "#js-order-cancel", function(e) {
      e.preventDefault();
      updateOrder("cancel");
    });

    $body.on("click", "#js-expedite", function(e) {
      e.preventDefault();
      updateOrder("expedite");
    });

    $jsSaveOrder.on("click", function(e) {
      e.preventDefault();
      if (store.orderMethod === "PUT") {
        updateOrder("edit");
      } else {
        createReplacementOrder();
      }
    });
  };

  return init;
}